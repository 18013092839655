<template>
	<div class="filter-bar">
		<div class="category-wrapper">
			<div>
				<p ref="dropdownRef" class="dropdown-text" @click.stop="handleMenu">
					{{ dropdowntext }}
				</p>
				<a
					class="btn"
					target="_blank"
					:disabled="targetUrl === false"
					:href="targetUrl"
					@click=executeTagmanager();
				>
					Boka bana
				</a>
			</div>
			<div v-show="menuOpen" class="dropdown-modal">
				<article v-if="mobileWidth" class="dropdown-banner">
					<p>Välj Center</p>
					<span class="icon" @click="handleMenu"></span>
				</article>
				<ul>
					<li v-for="(clubs, location) in facilitydata" :key="location">
						<p>{{ location }}</p>
						<hr />
						<p
							v-for="(item, i) in clubs"
							:key="i"
							class="name"
							@click="updateName(item.name, item.url)"
						>
							{{ item.name }}
						</p>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>
<script>
import facilities from '@/assets/facilities.json'
import axios from 'axios'
import { baseUrl } from '@/assets/apiLink.js'
export default {
	data: function () {
		return {
			facilitydata: {},
			dropdowntext: 'Välj en ort',
			targetUrl: false,
			targetName: '',
			menuOpen: false,
			mobileWidth: false,
			baseApiUrl: baseUrl,
		}
	},
	created() {
		window.addEventListener('click', this.handleOutsideClick)
	},
	destroyed() {
		window.removeEventListener('click', this.handleOutsideClick)
	},
	mounted: function () {
		/*this.facilitydata = facilities.sort((item1, item2) => {
			if (item1 < item2) {
				return -1
			}
			if (item1 > item2) {
				return 1
			}
			return 0
		})
		this.facilitydata = facilities
			.filter(
				(item) =>
					item.status.open && item.categories[0].toLowerCase() === 'nordic wellness padelcenter'
			)
			.sort((item1, item2) => {
				if (item1.location < item2.location) {
					return -1
				}
				if (item1.location > item2.location) {
					return 1
				}
				return 0
			})
			.reduce((acc, curr) => {
				return {
					...acc,
					[curr.location]: [
						...(acc[curr.location] || []),
						{
							name: curr.name,
							url: curr.url,
						},
					],
				}
			}, {})
*/
		axios.get(this.baseApiUrl + '/umbraco/api/homepage/getclubs').then((response) => {
			let facilities = response.data
			this.facilitydata = facilities.sort((item1, item2) => {
				if (item1 < item2) {
					return -1
				}
				if (item1 > item2) {
					return 1
				}
				return 0
			})
			this.facilitydata = facilities
				.filter(
					(item) =>
						item.status.open && item.categories[0].toLowerCase() === 'nordic wellness padelcenter'
				)
				.sort((item1, item2) => {
					if (item1.location < item2.location) {
						return -1
					}
					if (item1.location > item2.location) {
						return 1
					}
					return 0
				})
				.reduce((acc, curr) => {
					return {
						...acc,
						[curr.location]: [
							...(acc[curr.location] || []),
							{
								name: curr.name,
								url: curr.booking_url,
							},
						],
					}
				}, {})
		})

		window.addEventListener('resize', () => {
			window.innerWidth < 421 ? (this.mobileWidth = true) : (this.mobileWidth = false)
		})
		window.innerWidth < 421 ? (this.mobileWidth = !this.mobileWidth) : (this.mobileWidth = false)
	},
	methods: {
		handleOutsideClick: function (event) {
			if (this.$refs.dropdownRef?.classList?.contains('menu-open')) {
				const clickOnModal = event?.path?.find((el) => {
					return el?.classList?.contains('filter-bar')
				})
				!clickOnModal && this.handleMenu()
			}
		},
		executeTagmanager: function () {
			window.dataLayer = window.dataLayer || []
			window.dataLayer.push({
				event: 'boka bana',
				bana: this.targetName, // Ersätt med vilken bana som de facto bokas
			})
			if (this.targetName.length > 0) {
				registerD7()
			}
		},
		updateName: function (name, url) {
			this.dropdowntext = name
			;(this.targetUrl = url), (this.targetName = name), (this.menuOpen = !this.menuOpen)
			this.$refs.dropdownRef.classList.toggle('menu-open')
			document.body.classList.toggle('modal-open')
		},

		handleMenu: function () {
			this.$refs.dropdownRef.classList.toggle('menu-open')
			this.menuOpen = !this.menuOpen
			window.innerWidth < 421 ? document.body.classList.toggle('modal-open') : null
		},
	},
}
</script>
<style scoped lang="scss">
.filter-bar {
	height: 50px;
	max-width: 500px;
	width: -webkit-fill-available;

	.category-wrapper {
		div {
			& > p:nth-of-type(1) {
				@include paragraph-small;

				font-size: 13px;
				display: flex;
				width: 100%;
				max-width: 330px;
				height: 50px;
				background-color: #fff;
				color: #000;
				border-radius: 25px;
				font-family: $secondary-font-sbold;
				align-items: center;
				line-height: normal;
				border: 1px solid #fff;
				cursor: pointer;
				text-transform: uppercase;
				justify-content: space-between;
				padding: 17px 10px 17px 30px;
				transition: all 0.1s ease-in-out;

				&.show-menu {
					border: unset;
					border: 1px solid black;
					border-radius: 3px;
				}

				&::after {
					display: block;
					pointer-events: all;
					content: '\33';
					font-family: $elegant-icons;
					font-size: 30px;
					transform: none;
					cursor: pointer;
				}
				&.menu-open {
					&::after {
						transform: rotate(180deg) !important;
					}
				}
			}
		}

		& > div:nth-of-type(1) {
			display: flex;
			align-items: center;

			@include max-width(small) {
				flex-direction: column;
				align-items: start;
				max-width: 330px;
			}

			.btn {
				@include paragraph-link;

				min-width: max-content;
				font-size: 14px;
				font-family: $secondary-font-sbold;
				line-height: normal;
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 15px 17px 15px 26px;
				border-radius: 25px;
				letter-spacing: 0.7px;
				background-color: $color-green;
				text-transform: uppercase;
				color: #fff;
				margin-left: 15px;

				&::after {
					display: block;
					margin-left: 45px;
					pointer-events: all;
					content: '\24';
					font-family: $elegant-icons;
					font-size: 20px;
					transform: none;
					cursor: pointer;
				}

				@include max-width(small) {
					width: 100%;
					margin-left: 0;
					margin-top: 15px;
				}
			}
		}
	}
	.dropdown-modal {
		z-index: 1;
		width: 490px;
		position: relative;
		top: 23px;
		border-radius: 8px;
		padding-top: 20px;
		padding-bottom: 20px;
		color: #000;
		background-color: #fff;
		overflow-y: scroll;

		.dropdown-banner {
			height: 70px;
			background-color: #54b848;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 26px 22px;
			margin-bottom: 20px;

			p {
				color: #fff;
				font-family: $secondary-font-sbold;
				text-transform: uppercase;
				font-size: 14px;
				letter-spacing: 0.7px;
			}

			.icon {
				&:after {
					display: block;
					pointer-events: all;
					content: '\4d';
					font-family: $elegant-icons;
					font-size: 25px;
					margin-bottom: 3px;
					transform: none;
					cursor: pointer;
					color: #fff;
				}
			}
		}

		ul {
			max-height: 355px;
			overflow-y: scroll;

			li {
				& > p:nth-of-type(1) {
					font-family: $secondary-font-sbold;
					text-transform: uppercase;
					font-size: 18px;
					letter-spacing: 0.9px;
					padding: 15px 0 12px 30px;

					@include max-width(mobile) {
						padding-left: 23px;
					}
				}

				hr {
					margin-bottom: 5px;
					width: 90%;
					color: $color-bg-grey;
					opacity: 0.5;
					margin-left: 30px;

					@include max-width(small) {
						width: 82%;
					}
					@include max-width(mobile) {
						margin-left: 23px;
					}
				}

				p {
					padding-left: 30px;
					@include max-width(mobile) {
						padding-left: 23px;
					}
				}

				.name {
					text-transform: uppercase;
					padding-top: 12px;
					padding-bottom: 12px;
					font-size: 14px;
					letter-spacing: 0.9px;
					cursor: pointer;
					&:hover {
						background-color: $color-bg-grey;
					}
				}
			}
			@include max-width(small) {
				max-height: unset;
				height: 100%;
			}
		}

		@include max-width(small) {
			position: relative;
			bottom: 80px;
			width: 100%;
			max-width: 330px;
		}

		@include max-width(mobile) {
			max-width: unset;
			position: fixed;
			padding-top: 0;
			left: 0;
			width: 100vw;
			top: 0;
			z-index: 9999;
			height: 100vh;
			border-radius: 0;
			padding-bottom: 0;
		}

		@media (max-width: 355px) {
			margin-top: 0;
		}
	}
}

.pinkRibbonTheme {
	.filter-bar {
		.category-wrapper {
			.btn {
				background-color: $color-darkpink !important;
			}
		}
	}
}
</style>
